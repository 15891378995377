<template>
  <p>
    <span v-if="shippingInfo.unit">{{ shippingInfo.unit }} - </span>
    {{ shippingInfo.streetAddress }}
  </p>

  <p v-if="shippingInfo.streetAddress2">
    {{ shippingInfo.streetAddress2 }}
  </p>
  <p>
    {{ shippingInfo.city }} {{ shippingInfo.province }} {{ shippingInfo.postal }}
  </p>

  <a v-if="shippingInfo.phone" :href="`tel:${shippingInfo.phone}`">
    {{ shippingInfo.phone }}
  </a>
</template>

<script>
// import filters from '@/utils/filters'

export default {
  props: ['shippingInfo'],
  setup() {

    // return {
    //   ...filters
    // }
  }
}
</script>