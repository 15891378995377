<template>
  <div v-if="campaign && invites">
    <nav class="navbar top" role="navigation" aria-label="main navigation">
      <div class="navbar-brand is-justify-content-space-between">
        <div class="navbar-item is-block">
          <h1 class="title mb-0" style="margin-top: -8px;">
            {{ campaign.campaignName }}
          </h1>
          <p class="help has-text-weight-normal mt-0">
            {{ formatDate(campaign.created) }} - {{ formatDate(campaign.submittedAt) }}
          </p>
        </div>
      </div>

      <PanelTabs
        :options="[
          { label: 'Orders', value: null },
          { label: 'Campaign details', value: 'campaign' },
          { label: 'Payment', value: 'payment' },
        ]"
        v-model="currentFilter"
      />
    </nav>

    <section class="section">
      <button class="button is-light" @click="exportCSV()">
        Export CSV
      </button>
    </section>

    <section v-if="!currentFilter" class="section pt-0">
      <div class="table-container">
        <table class="table is-fullwidth">
          <thead v-if="unfulfilledOrders.length" class="has-background-light">
            <tr>
              <th colspan="4">Unfulfilled</th>
            </tr>
          </thead>
          <tbody v-if="unfulfilledOrders.length">
            <OrderRow
              v-for="order in unfulfilledOrders"
              :key="order.id"
              :order="order"
            />
          </tbody>
          <thead v-if="fulfilledOrders.length" class="has-background-light">
            <tr>
              <th colspan="4">Fulfilled</th>
            </tr>
          </thead>
          <tbody v-if="fulfilledOrders.length">
            <OrderRow
              v-for="order in fulfilledOrders"
              :key="order.id"
              :order="order"
            />
          </tbody>
        </table>
      </div>
    </section>

    <section v-if="currentFilter === 'campaign'" class="section">
      <div class="columns">
        <div class="column is-9">
          <div class="columns is-multiline">
            <div class="column is-6 field">
              <label class="label">Account name</label>
              <p>{{ campaign.accountName }}</p>
            </div>
            <div class="column is-6 field">
              <label class="label">Account email</label>
              <p>{{ campaign.accountEmail }}</p>
            </div>
            <div class="column is-6 field">
              <label class="label">Account Company</label>
              <p>{{ campaign.accountCompany }}</p>
            </div>
            <div class="column is-6 field">
              <label class="label">Campaign name</label>
              <p>{{ campaign.campaignName }}</p>
            </div>
            <div class="column is-6 field">
              <label class="label">Items per recipient</label>
              <p>{{ campaign.chooseType }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <template v-if="currentFilter === 'payment'">
      <PaymentSummary
        :orders="answeredInvites"
        :authorized="campaign.authorized"
      />

      <div v-if="!campaign.completedAt" class="button-footer has-padding-bottom">
        <button
          :class="`button is-black px-6 ${isPending && 'is-loading'}`"
          :disabled="unfulfilledOrders.length"
          @click="showConfirmSubmitModal = true"
        >
          Complete Order
        </button>
      </div>
    </template>

    <Modal
      v-if="showConfirmSubmitModal"
      title="Complete Order"
      :isSmall="true"
      @close="showConfirmSubmitModal = false"
    > 
      <p>This will charge the clients card</p>

      <template v-slot:footer>
        <button class="button is-black" @click="completeOrder()">
          Complete Order
        </button>
        <button class="button" @click="showConfirmSubmitModal = false">
          Cancel
        </button>
      </template>
    </Modal>
  </div>
</template>

<script>
import { inject, ref, computed } from 'vue'
import getDocument from '@/composables/getDocument'
import getCollection from '@/composables/getCollection'
import useDocument from '@/composables/useDocument'
import useCollection from '@/composables/useCollection'
import PanelTabs from '@/components/Campaign/PanelTabs.vue'
import OrderRow from '@/components/Admin/AdminOrderRow.vue'
import PaymentSummary from '@/components/Campaign/PaymentSummary.vue'
import Modal from '@/components/Modals/Modal.vue'
import { timestamp } from '@/firebase/config'
import filters from '@/utils/filters'

export default {
  props: ['campaignId'],
  components: { PanelTabs, OrderRow, PaymentSummary, Modal },
  setup(props) {
    const { document: campaign } = getDocument('campaigns', props.campaignId)
    const { documents: invites } = getCollection('invites', ['campaignId', '==', props.campaignId])

    console.log('campaign', campaign)

    const currentFilter = ref(null)
    const showConfirmSubmitModal = ref(false)
    const isPending = inject('isPending')

    const answeredInvites = computed(() => {
      return invites.value && invites.value.filter(invite => invite.acceptedAt)
    })

    const unfulfilledOrders = computed(() => {
      return invites.value && invites.value.filter(invite => invite.acceptedAt && !invite.shippedAt)
    })

    const fulfilledOrders = computed(() => {
      return invites.value && invites.value.filter(invite => invite.shippedAt)
    })

    const exportCSV = () => {
      let csvString = ''
      invites.value.map(invite => {
        invite.chosenProducts.map(product => {
          csvString += `${invite.email},${invite.name},${product.SKU},`

          let productInfo = ''
          if (product.chosenSex) productInfo += `sex: ${product.chosenSex};`
          if (product.chosenSize) productInfo += ` size: ${product.chosenSize};`
          if (product.chosenColor) productInfo += ` color: ${product.chosenColor};`
          csvString += productInfo + ','

          const address = `${invite.shippingInfo.unit ? invite.shippingInfo.unit + '-' : ''} ${invite.shippingInfo.streetAddress} ${invite.shippingInfo.streetAddress2} ${invite.shippingInfo.city} ${invite.shippingInfo.province} ${invite.shippingInfo.postal}\n`
          csvString += address
        })
      })
      const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8' })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = `${props.campaignId}.csv`
      a.click()
    }

    const completeOrder = async () => {
      isPending.value = true

      const { updateDoc: updateCampaign } = useDocument('campaigns', props.campaignId)      
      await updateCampaign({
        completedAt: timestamp(),
      })

      const { addDoc: addNotification } = useCollection('accounts', campaign.value.accountId, 'notifications')
      await addNotification({
        type: 'campaignFulfilled',
        message: `${campaign.value.campaignName} is complete - all your recipients have been shipped their items.`,
        campaignId: props.campaignId,
        seen: false,
        created: timestamp()
      })

      isPending.value = false
      showConfirmSubmitModal.value = false
    }

    return {
      campaign,
      currentFilter,

      invites,
      answeredInvites,
      fulfilledOrders,
      unfulfilledOrders,

      exportCSV,
      showConfirmSubmitModal,
      completeOrder,
      
      isPending,
      ...filters
    }
  }
}
</script>