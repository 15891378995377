<template>
  <section class="section">
    <h2 class="subtitle is-size-3 level is-mobile">
      Payment
      <span v-if="includeTax" v-html="formatCurrency(grandTotal + calcTax(grandTotal, .13), 'CAD')" />
      <span v-else v-html="formatCurrency(grandTotal, 'CAD')" />
    </h2>

    <hr />

    <slot name="items"></slot>

    <hr v-if="$slots.items" />

    <div class="field">
      <h3 class="subtitle is-size-6">
        Subtotal
      </h3>
      <div class="level is-mobile">
        <span>Items ({{ getItemsLength }})</span>
        <strong v-html="formatCurrency(getCampaignTotal, 'CAD')" />
      </div>
      <div class="level is-mobile">
        <span>Shipping &amp; Handling</span>
        <span v-html="formatCurrency(getShippingTotal, 'CAD')" />
      </div>
      <div class="level is-mobile">
        <span>Swagible fee</span>
        <span v-html="formatCurrency(getSwagibleFee, 'CAD')" />
      </div>
      <div v-if="includeTax" class="level is-mobile">
        <span>Taxes</span>
        <span v-html="formatCurrency(calcTax(grandTotal, .13), 'CAD')" />
      </div>
    </div>

    <hr />

    <div v-if="authorized" class="level is-mobile">
      <span>Authorized</span>
      <span v-html="formatCurrency(authorized * .01, 'CAD')" />
    </div>
    <div class="level is-mobile">
      <label class="subtitle is-size-6 mb-0">
        Total charge
      </label>
      <span v-if="includeTax" v-html="formatCurrency(grandTotal + calcTax(grandTotal, .13), 'CAD')" />
      <span v-else v-html="formatCurrency(grandTotal, 'CAD')" />
    </div>
  </section>
</template>

<script>
import { computed } from 'vue'
import Modal from '@/components/Modals/Modal.vue'
import filters from '@/utils/filters'

export default {
  props: ['orders', 'itemsLength', 'authorized'],
  emits: ['emitShippingAndFees'],
  components: { Modal },
  setup(props, { emit }) {
    const includeTax = process.env.VUE_APP_INCLUDE_TAX

    const getItemsLength = computed(() => {
      let total = 0
      props.orders.forEach(order => {
        total += order.chosenProducts.length
      })
      return total
    })

    const getCampaignTotal = computed(() => {
      const getOrderTotal = (products) => {
        return products.reduce((acc, { charge }) => acc + Number(charge), 0)
      }

      return props.orders.reduce((total, { chosenProducts }) => total + getOrderTotal(chosenProducts), 0)
    })
    
    const getShippingTotal = computed(() => {
      return props.orders.length * 10
    })

    const getSwagibleFee = computed(() => {
      return getCampaignTotal.value * .10
    })

    const calcTax = (amount, percent) => {
      return amount * percent
    }

    const grandTotal = getCampaignTotal.value + getShippingTotal.value + getSwagibleFee.value

    emit('emitShippingAndFees', {
      'grandTotal': grandTotal,
      'Shipping & Handling': getShippingTotal.value,
      'Swagible fee': getSwagibleFee.value,
      'Taxes': calcTax(grandTotal, .13)
    })

    return {
      includeTax,

      getItemsLength,
      getCampaignTotal,
      getShippingTotal,
      getSwagibleFee,
      calcTax,

      grandTotal,

      ...filters
    }
  }
}
</script>