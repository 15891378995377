<template>
  <tr>
    <td>
      <p>{{ order.name }}</p>
      <p class="help has-text-weight-normal">{{ order.email }}</p>
    </td>
    <td v-if="order.shippedAt">
      <p>{{ formatDate(order.shippedAt) }}</p>
      <p class="help">submitted {{ formatDate(order.acceptedAt) }}</p>
    </td>
    <td v-else>
      {{ formatDate(order.acceptedAt) }}
    </td>
    <td class="has-text-right">
      <button class="button is-info" @click="showModal = true">
        View Order
      </button>
      <a v-if="order.shippedAt" :href="order.trackingLink" class="button is-success ml-3" target="_blank">
        Track Order
      </a>
    </td>
  </tr>

  <Teleport to="body">
    <FulfillOrderModal
      v-if="showModal"
      :order="order"
      @close="showModal = false"
    />
  </Teleport>
</template>

<script>
import { ref } from 'vue'
import FulfillOrderModal from '@/components/Admin/AdminFulfillOrderModal.vue'
import Icon from '@/components/Icon.vue'
import filters from '@/utils/filters'

export default {
  props: ['order'],
  components: { FulfillOrderModal, Icon },
  setup() {
    const showModal = ref(false)

    return {
      showModal,
      ...filters,
    }
  }
}
</script>

<style scoped>
.is-clickable:hover {
  background-color: #fafafa;
}
</style>