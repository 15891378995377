<template>
  <Modal
    title="Fulfill Order"
    @close="$emit('close')"
  >
    <div class="field">
      <label class="label">
        Shipping address
      </label>
      <ShippingAddress :shippingInfo="order.shippingInfo" />
    </div>

    <div class="field">
      <label class="label">
        Items
      </label>
      <OrderItem
        v-for="product in order.chosenProducts"
        :key="product.id"
        :item="product"
        :isAdmin="true"
      />
    </div>

    <div v-if="!order.shippedAt" class="field">
      <label class="label">
        Send customer notification of shipment
      </label>
      <div class="control">
        <label class="checkbox">
          <input type="checkbox">
          Send to client
        </label>
        <label class="checkbox ml-3">
          <input type="checkbox" v-model="sendRecipientEmail">
          Send to recipient
        </label>
      </div>
    </div>

    <div v-if="!order.shippedAt" class="field">
      <label class="label">
        Tracking link
      </label>
        <input class="input" v-model="order.trackingLink" type="url" />
    </div>

    <article v-if="error" class="message is-danger">
      <div class="message-body">
        {{ error }}
      </div>
    </article>

    <template v-slot:footer>
      <button
        v-if="!order.shippedAt"
        :class="`button is-black ${isPending && 'is-loading'}`"
        :disabled="!order.trackingLink"
        @click="handleSubmit()"
      >
        Save
      </button>
      <button class="button" @click="$emit('close')">
        Close
      </button>
    </template>
  </Modal>
</template>

<script>
import { inject, ref } from 'vue'
import useDocument from '@/composables/useDocument'
import useCollection from '@/composables/useCollection'
import Modal from '@/components/Modals/Modal.vue'
import ShippingAddress from '@/components/ShippingAddress.vue'
import OrderItem from '@/components/Campaign/OrderItem.vue'
import { timestamp } from '@/firebase/config'
import { validURL } from '@/utils/utils'
import filters from '@/utils/filters'
import {updateRecipientSwagShippedEmail} from '@/utils/mailHandler.js'

export default {
  props: ['order'],
  emits: ['close'],
  components: { Modal, ShippingAddress, OrderItem },
  setup(props, { emit }) {
    console.log('props', props)

    const sendRecipientEmail = ref(true)
    const isPending = inject('isPending')
    const error = ref(null)
    
    const handleSubmit = async () => {
      const validTrackingURL = validURL(props.order.trackingLink)

      if (!validTrackingURL) {
        error.value = 'please enter a valid tracking URL'
        return
      }
      
      error.value = null
      isPending.value = true

      const { updateDoc: updateInvite } = useDocument('invites', props.order.id)
      await updateInvite({
        trackingLink: props.order.trackingLink,
        shippedAt: timestamp(),
        lastUpdated: timestamp()
      })

      if (sendRecipientEmail.value) {
        await updateRecipientSwagShippedEmail(props)

        // Moved to mailHandler.js
        // const { addDoc: addEmail } = useCollection('mail')
        // await addEmail({
        //   to: props.order.email,
        //   templateId: 'd-82d2ca02d27c495b9916404ed62db055', // SWAGIBLE:RECIPIENTSWAGSHIPPED
        //   dynamic_template_data: {
        //     recipient_first_name: props.order.name,
        //     tracking_company: 'TODO tracking_company',
        //     tracking_number: 'TODO tracking_number',
        //     tracking_url: props.order.trackingLink,
        //   }
        // })
      }
      isPending.value = false
      emit('close')
    }

    return {
      sendRecipientEmail,

      handleSubmit,
      isPending,
      error,
      ...filters
    }
  }
}
</script>